<template>
  <div class="home safe-area-inset-bottom" ref="DOM">
    <div class="swiper-wrap">
      <home-swiper :images="images"></home-swiper>
    </div>
    <!-- content -->
    <div ref="scroll" class="content">
      <!-- 发起会议 -->
      <div class="meeting-box">
        <div class="meeting-title dfr">
          <span class="num">{{ count }}</span>
          <span class="desc">名私董加入私董会</span>
        </div>
        <div v-show="isLogin && showBtn">
          <div v-if="type == 1" class="mauto dfr cross-center main-between">
            <icy-button @click.native="sendMeeting(0)" width="3.28rem"
              >我要发起会议</icy-button
            >
            <!-- 主持人才能代发 -->
            <icy-button width="3.28rem" @click.native="sendMeeting(1)"
              >代案主发起会议</icy-button
            >
          </div>
          <div v-else class="mt32">
            <icy-button @click.native="sendMeeting(0)" width="6.78rem"
              >我要发起会议</icy-button
            >
          </div>
        </div>
      </div>
      <!-- notice -->
      <div class="notice-wrap">
        <home-notice :article="article"></home-notice>
      </div>

      <!-- 明星主持人 -->
      <div v-if="moderatorList.length" class="moderator-wrap">
        <div
          @click="goHostList"
          class="look-more-box dfr cross-center main-between "
        >
          <app-title class="moderator-title " title="明星主持人"></app-title>
          <div class="look-more fs24 fc999 dfr cross-center ">
            查看全部
            <van-icon class="look-more-icon" size=".24rem" name="arrow" />
          </div>
        </div>
        <div class="dfr  flex-wrap moderator-card-box">
          <div
            v-for="(item, index) in moderatorList"
            :key="index"
            class="moderator-card dfr"
          >
            <moderator-card :info="item" />
          </div>
        </div>
      </div>

      <!-- 推荐场所 -->
      <div v-if="siteList.length" class="mt80  ">
        <div class="dfr main-between cross-center pl28 pr28">
          <app-title title="推荐场所"></app-title>
          <div class="fs24 fc999 dfr cross-center" @click="goSiteList">
            查看全部
            <van-icon class="ml6" size=".24rem" name="arrow" />
          </div>
        </div>
        <div class="hotel-swiper-wrap mt28 dfr cross-center main-between">
          <swiper
            ref="myProductSwiper"
            class="por w100 "
            :options="swiperOption"
            v-if="siteList.length >= 3"
          >
            <swiper-slide
              class="banner-swiper-slide h100 w100"
              v-for="(item, index) in siteList"
              :key="index"
            >
              <div class="site-item ml15 mr15 mb30" @click="goDetail(item.id)">
                <div class="w100 site-img">
                  <img :src="item.cover_image" alt="" class="w100 h100" />
                </div>
                <div class="fs28 fc333 bgfff w100 pt20 pl20 pb20 pr20">
                  <div class="site-address">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            ref="myProductSwiper"
            class="por w100 dfr"
            :options="swiperOption"
            v-if="siteList.length < 3"
          >
            <div
              class="banner-swiper-slide h100 w100"
              v-for="(item, index) in siteList"
              :key="index"
            >
              <div
                class="site-item site-item-w ml15 mr15 mb30"
                @click="goDetail(item.id)"
              >
                <div class="w100 site-img">
                  <img :src="item.cover_image" alt="" class="w100 h100" />
                </div>
                <div class="fs28 fc333 bgfff w100 pt20 pl20 pb20 pr20">
                  <div class="site-address">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 明星私董 -->
      <div v-if="sidongList.length" class="moderator-wrap">
        <div
          @click="goSidongList"
          class="look-more-box dfr cross-center main-between "
        >
          <app-title class="moderator-title" title="明星私董"></app-title>
          <div class="look-more fs24 fc999 dfr cross-center ">
            查看全部
            <van-icon class="look-more-icon" size=".24rem" name="arrow" />
          </div>
        </div>
        <div v-if="sidongList.length <= 2" class="sidong-box dfr">
          <div
            v-for="(item, index) in sidongList"
            :key="index"
            class="sidong-card-wrap"
          >
            <sidong-card :info="item"></sidong-card>
          </div>
        </div>
        <div v-else class="sidong-box">
          <home-s-swiper :infoList="sidongList"></home-s-swiper>
        </div>
      </div>
    </div>
    <tab-bar :active="activeTab"></tab-bar>
  </div>
</template>

<script>
import { isWeiXinBrowser, isApplet } from "../utils/util";
import { getParam, getParamByURL, wechatBackWithCode } from "../utils/logon";
export default {
  data() {
    return {
      images: [],
      count: "",
      article: [],
      moderatorList: [],
      sidongList: [],
      siteList: [], //场所
      // 是否登录
      isLogin: false,
      // 是否私董
      type: "",
      activeTab: "0",
      swiperOption: {
        loop: false,
        slidesPerView: 2.22,
        spaceBetween: 0
      },
      showBtn: false
    };
  },
  created() {
    this.$title("云派私董会");
    this.initPage();
  },
  activated() {
    // this.$title("云派私董会");
    // this.initPage();
  },
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();

    this.$localStore.set("homeScrollTop", scrollTop);
    next();
  },
  mounted() {},
  methods: {
    async initPage() {
      this.activeTab = "0";
      this.checkIsLogin();
      this.initHost();
      this.initSidong();
      this.getHomeInfo();
      this.getHomeSpaceList();
      let scrollTop = this.$localStore.get("homeScrollTop") || 0;
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo(0, scrollTop);
          if (document.documentElement.scrollTop) {
            document.documentElement.scrollTop = scrollTop;
          } else if (window.pageYOffset) {
            window.pageYOffset = scrollTop;
          } else if (document.body.scrollTop) {
            document.body.scrollTop = scrollTop;
          }
        }, 1000);
      });

      if (this.isLogin) {
        this.getUserInfo();
      }
    },
    // 是否登录
    checkIsLogin() {
      let isLogin = this.$localStore.get("isLogin");
      if (isLogin) {
        this.isLogin = true;
      }
    },
    // 获取首页信息
    async getHomeInfo() {
      this.images = [];
      const resp = await this.$API
        .get("homeInfo", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        // 轮播图
        resp.data.images.forEach(item => {
          this.images.push(item);
        });
        // 私董数量
        this.count = resp.data.count;

        // 通告栏
        this.article = resp.data.articles;
      }
    },
    // 获取推荐场所
    async getHomeSpaceList() {
      const resp = await this.$API
        .get("homeSpaceList", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.siteList = resp.data;
      }
    },
    // 明星主持人
    async initHost() {
      const data = {
        isStar: 1,
        page: 1,
        pageSize: 6
      };
      const resp = await this.$API
        .get("moderatorList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data.list;
        list.forEach(item => {
          let _fieldsString = [];
          item.field.forEach(_item => {
            _fieldsString.push(_item.name);
          });
          item.fields = _fieldsString.join(",");
        });
        this.moderatorList = resp.data.list;
      }
    },
    // 私董
    async initSidong() {
      const data = {
        isStar: 1
      };
      const resp = await this.$API
        .get("sidongList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data.list;
        list.forEach(item => {
          let _fieldsString = [];
          item.field.forEach(_item => {
            _fieldsString.push(_item.name);
          });
          item.fields = _fieldsString.join(",");
        });
        this.sidongList = resp.data.list;
      }
    },
    // 获取个人信息
    async getUserInfo() {
      const resp = await this.$API.get("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        this.type = resp.data.info.type;
        this.userInfo = resp.data;
        const sendNum = resp.data.info.meeting_count;
        const joinNum = resp.data.info.meeting_user_count;
        const hostNum = resp.data.info.host_meeting_count;
        // meet 发起的 host 主持的 meeting_user 参加的
        const sendWaitNum = resp.data.info.wait_meeting_count;
        const joinWaitNum = resp.data.info.wait_meeting_user_count;
        const hostWaitNum = resp.data.info.wait_host_meeting_count;
        let isGoMe = this.$store.state.alreadyGoMe;
        const needUpdateWxcode = this.$localStore.get("needUpdateWxcode");
        // 不是小程序环境才会执行
        if (needUpdateWxcode && !isApplet()) {
          let code = "";
          if (isWeiXinBrowser()) {
            code = getParam("code");
            if (!code) {
              wechatBackWithCode();
            }
          } else {
            code = getParamByURL(location.href, "code");
          }
          if (code) {
            const resp = await this.$API
              .post("bindWechat", { code })
              .then(resp => resp);

            if (resp.code == 1000) {
              this.$localStore.set("needUpdateWxcode", false);
            }
          }
        }
        if (!isGoMe) {
          if (
            sendNum ||
            joinNum ||
            hostNum ||
            sendWaitNum ||
            joinWaitNum ||
            hostWaitNum
          ) {
            // this.$store.commit("changeGoMe");
            // this.$goReplace("me");
          }
        }
        this.showBtn = true;
      }
    },
    goHostList() {
      this.$go("hosts");
    },
    goSiteList() {
      this.$go("siteList");
    },
    goSidongList() {
      this.$go("directors");
    },
    sendMeeting(isReplace) {
      let isLogin = this.$localStore.get("isLogin");
      if (!isLogin) {
        this.$go("login");
        return;
      }
      this.$go("createMeeting", { isReplace });
    },
    goDetail(id) {
      this.$go("siteDetail", { id: id });
    }
  }
};
</script>
<style lang="less" scoped>
.look-more {
  padding: 0.02rem 0.28rem 0 0;
}
.look-more-icon {
  margin-left: 0.06rem;
}
.home {
  width: 7.5rem;
  overflow-x: hidden;
}
.swiper-wrap {
  width: 7.5rem;
  height: 5.12rem;
}
/* 发起会议 */
.meeting-box {
  width: 7.5rem;
  padding: 0 0.36rem 0.6rem;
  background-color: #ffffff;
  border-radius: 0.24rem 0.24rem 0.4rem 0.4rem;
  z-index: 2;
  box-shadow: 0px 0.1rem 0.24rem 0px rgba(0, 0, 0, 0.05);
  margin-top: -0.24rem;
  position: relative;
}
.meeting-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.meeting-title .num {
  color: #cb9025;
  font-size: 0.72rem;
  margin-top: 0.6rem;
}
.meeting-title .desc {
  font-size: 0.28rem;
  color: #666666;
  margin-top: 0.86rem;
  margin-left: 0.08rem;
}
.mauto {
  margin-top: 0.32rem;
}

// 明星主持人
.moderator-wrap {
  margin-top: 0.5rem;
  .moderator-title {
    margin-left: 0.28rem;
  }
  .moderator-card-box {
    padding-left: 0.28rem;
    padding-top: 0.08rem;
  }
  .moderator-card {
    margin-right: 0.2rem;
    margin-top: 0.2rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

// 场所
.site-item {
  height: 3.52rem;
  border-radius: 0.12rem;
  overflow: hidden;
  box-shadow: 0 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  .site-img {
    height: 2.32rem;
    img {
      object-fit: cover;
    }
  }
  .site-address {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.site-item-w {
  width: 3.32rem;
}

// 明星私董
.sidong-box {
  padding: 0.28rem 0 0.28rem 0.28rem;
  .sidong-card-wrap {
    margin-right: 0.28rem;
  }
}
</style>
